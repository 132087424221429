import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
// material
import {
  Box,
  Backdrop,
  Paper,
  Typography,
  Stack,
  FormHelperText,
  TextField,
  CircularProgress,
  Button,
  MenuItem,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
  FormGroup,
  FormLabel
} from '@mui/material';
import { getDirectorDashboard } from 'redux/slices/director-dashboard';
import { LoadingButton } from '@mui/lab';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
//
import Scrollbar from '../../Scrollbar';
import { MIconButton } from '../../@material-extend';
import { Form, FormikProvider, useFormik, FormikState } from 'formik';
// redux
import { RootState, useDispatch, useSelector } from '../../../redux/store';
import {
  getHomeworkById,
  updateHomework,
  getRecurringHomework,
  recurringHomework
} from '../../../redux/slices/homework';
import dayjs from 'dayjs';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 360;

type EditHomeworkFormProps = {
  editId: number;
  openForm: boolean;
  formClosed: VoidFunction;
  processedSuccess: VoidFunction;
  onDelete: VoidFunction;
  classId: any;
};

type Question = {
  id?: string;
  question: string;
};

export default function EditHomeworkForm({
  editId,
  openForm,
  formClosed,
  processedSuccess,
  onDelete,
  classId
}: EditHomeworkFormProps) {
  const [formType, setFormType] = useState<'edit' | 'recurring'>('edit');
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [homeworkId, setHomeworkId] = useState<number | null>(null);
  const dispatch = useDispatch();
  const [data, setData] = useState<any>([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    homework,
    response: homeworkResponse,
    recurringDays,
    untilDate
  } = useSelector((state: RootState) => state.homework);
  const [editData, setEditData] = useState(homework);

  useEffect(() => {
    setOpen(openForm);
  }, [openForm]);
  useEffect(() => {
    const fetchData = async () => {
      const result: any = await dispatch(getDirectorDashboard(classId));
      console.log('results', result);
      setData(result.data[0] || {});
    };
    fetchData();
  }, []);
  useEffect(() => {
    setIsLoading(true);
    dispatch(getHomeworkById(editId))
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
    setHomeworkId(editId);
  }, [editId, dispatch]);

  useEffect(() => {
    setEditData(homework);
  }, [homework]);
  console.log('EditId', editId);
  console.log('ClassId', classId);
  useEffect(() => {
    if (
      homeworkResponse &&
      (homeworkResponse.type === 'UPDATE_HOMEWORK_SUCCESS' ||
        homeworkResponse.type === 'RECURRING_HOMEWORK_SUCCESS')
    ) {
      enqueueSnackbar('Update success', {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
      processedSuccess();
      handleClose();
    }
  }, [homeworkResponse, enqueueSnackbar]);

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
    formClosed();
  };

  const handleFormTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormType(event.target.value as 'edit' | 'recurring');
  };

  useEffect(() => {
    const fetchRecurringHomework = async () => {
      if (formType === 'recurring') {
        await dispatch(getRecurringHomework(homeworkId));
      }
    };
    fetchRecurringHomework();
  }, [formType, homeworkId, dispatch]);

  const UpdateHomeworkSchema = Yup.object().shape({
    title: Yup.string().required('Homework title is required'),
    date: Yup.string().required('Homework date is required'),
    chapter: Yup.number().required('Chapter is required'),
    point: Yup.number().typeError('Point must be a number').required('Point is required')
  });

  const RecurringHomeworkSchema = Yup.object().shape({
    days: Yup.array().min(1, 'Select at least one day').required('Days are required'),
    untilDate: Yup.string().required('Until date is required').nullable()
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: editData?.title ?? '',
      point: editData?.point ?? '',
      chapter: editData?.chapter ?? '',
      date: editData?.date ? dayjs(editData.date) : null,
      questions: editData?.questions ?? ([] as Question[]),
      deletedQuestions: [],
      days: recurringDays ?? [],
      untilDate: untilDate ? dayjs(untilDate) : null
    },
    validationSchema: () => (formType === 'edit' ? UpdateHomeworkSchema : RecurringHomeworkSchema),
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        if (formType === 'edit') {
          let formData = new FormData();
          formData.append(`title`, values.title);
          formData.append(`point`, values.point);
          formData.append('chapter', values.chapter);
          formData.append('classid', classId);
          formData.append(`totalQuestions`, values.questions.length.toString());
          formData.append(`date`, dayjs(values.date).format('YYYY-MM-DD'));
          values.questions.map((e: any, index: number) => {
            formData.append(`id${index}`, e.id ?? '');
            formData.append(`question${index}`, e.question);
          });
          formData.append('deletedQuestions', values.deletedQuestions.join(','));
          // await dispatch(updateHomework(homeworkId, formData));
          // await dispatch(updateHomework(homeworkId, formData));
          await dispatch(updateHomework(homeworkId, formData));
        } else {
          const { days, untilDate } = values;
          await dispatch(
            recurringHomework(homeworkId, dayjs(untilDate).format('YYYY-MM-DD'), days.join(','))
          );
        }
        setSubmitting(false);
      } catch (error: any) {
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const { errors, touched, values, handleSubmit, isSubmitting, getFieldProps, setFieldValue } =
    formik;
  const daysArray = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const set = new Set(formik.values.days);
    if (set.has(value)) {
      set.delete(value);
    } else {
      set.add(value);
    }
    formik.setFieldValue('days', Array.from(set));
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          onClick={handleClose}
        />

        <Box
          sx={[
            {
              top: 0,
              bottom: 0,
              right: 0,
              position: 'fixed',
              zIndex: 2001,
              ...(open && { right: 0 })
            },
            (theme) => ({
              [theme.breakpoints.down('sm')]: {
                top: '70px'
              }
            })
          ]}
        >
          <Paper
            sx={{
              height: 1,
              width: '0px',
              overflow: 'hidden',
              borderRadius: 0,
              boxShadow: (theme) => theme.customShadows.z24,
              transition: (theme) => theme.transitions.create('width'),
              ...(open && { width: DRAWER_WIDTH })
            }}
          >
            <Backdrop
              sx={{
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                position: 'absolute'
              }}
              open={isLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ py: 2, pr: 1, pl: 2.5 }}
            >
              <Typography variant="subtitle1">
                {formType === 'edit' ? 'Update Homework' : 'Recurring Homework'}
              </Typography>
              <MIconButton onClick={handleClose}>
                <Icon icon={closeFill} width={20} height={20} />
              </MIconButton>
            </Stack>

            <Scrollbar sx={{ height: 1 }}>
              <Stack spacing={4} sx={{ pt: 3, px: 3, pb: 15 }}>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="formType"
                    name="formType"
                    value={formType}
                    onChange={handleFormTypeChange}
                  >
                    <FormControlLabel value="edit" control={<Radio />} label="Edit Homework" />
                    <FormControlLabel
                      value="recurring"
                      control={<Radio />}
                      label="Recurring Homework"
                    />
                  </RadioGroup>
                </FormControl>

                {formType === 'edit' ? (
                  <>
                    <Stack spacing={1.5}>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <TextField
                          fullWidth
                          label="Title"
                          variant="filled"
                          {...getFieldProps('title')}
                          error={Boolean(touched.title && errors.title)}
                          helperText={touched.title && errors.title}
                        />
                      </Stack>

                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <TextField
                          fullWidth
                          label="Point"
                          variant="filled"
                          type="number"
                          {...getFieldProps('point')}
                          error={Boolean(touched.point && errors.point)}
                          helperText={touched.point && errors.point}
                        />
                      </Stack>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <TextField
                          fullWidth
                          label="Chapter"
                          variant="filled"
                          {...getFieldProps('chapter')}
                          error={Boolean(touched.chapter && errors.chapter)}
                          helperText={touched.chapter && errors.chapter}
                        />
                      </Stack>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          {/* <DateCalendar
                            value={values.date}
                            onChange={(newValue) => setFieldValue('date', newValue)}
                          /> */}
                        </LocalizationProvider>
                      </Stack>
                      {touched.date && errors.date && (
                        <FormHelperText error={true}>{errors.date}</FormHelperText>
                      )}

                      {values.questions.map((e: any, index: number) => (
                        <>
                          <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            spacing={{ xs: 3, sm: 2 }}
                            key={`${index}1`}
                          >
                            <TextField
                              fullWidth
                              label={`Question ${index + 1}`}
                              variant="filled"
                              value={values.questions[index].question}
                              onChange={(newValue) => {
                                setFieldValue(
                                  'questions',
                                  values.questions.map((e: any, i: number) => {
                                    return i === index
                                      ? { ...e, question: newValue.target.value }
                                      : e;
                                  })
                                );
                              }}
                            />
                          </Stack>
                          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                            <Button
                              fullWidth
                              size="large"
                              type="button"
                              variant="contained"
                              color="error"
                              onClick={() => {
                                setFieldValue(
                                  'questions',
                                  values.questions.filter((e: any, i: number) => {
                                    if (i === index && e.id) {
                                      setFieldValue('deletedQuestions', [
                                        ...values.deletedQuestions,
                                        e.id
                                      ]);
                                    }
                                    return i !== index;
                                  })
                                );
                              }}
                            >
                              Remove Question
                            </Button>
                          </Stack>
                        </>
                      ))}
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <Button
                          fullWidth
                          size="large"
                          type="button"
                          variant="contained"
                          onClick={() => {
                            setFieldValue('questions', [
                              ...values.questions,
                              {
                                question: '',
                                questionType: '',
                                option1: '',
                                option2: '',
                                option3: '',
                                option4: ''
                              }
                            ]);
                          }}
                        >
                          Add Question
                        </Button>
                      </Stack>
                    </Stack>

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1.5}>
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        disabled={isSubmitting}
                      >
                        Update
                      </LoadingButton>
                      <Button
                        fullWidth
                        size="large"
                        type="button"
                        variant="contained"
                        color="error"
                        onClick={onDelete}
                      >
                        Delete
                      </Button>
                    </Stack>
                  </>
                ) : (
                  <Stack spacing={1.5}>
                    <Box>
                      <FormLabel component="legend">Select Days of the Week</FormLabel>
                      <FormGroup>
                        {/* {daysArray.map((day) => (
                          <FormControlLabel
                            key={day}
                            control={
                              <Checkbox
                                checked={formik.values.days.includes(day)}
                                onChange={handleToggle}
                                name="days"
                                value={day}
                              />
                            }
                            label={day}
                          />
                        ))} */}

                        {daysArray.map((day) => (
                          <FormControlLabel
                            key={day}
                            control={
                              <Checkbox
                                checked={formik.values.days.includes(day)}
                                onChange={handleToggle}
                                name="days"
                                value={day}
                                disabled={!data[day.toLowerCase()]}
                              />
                            }
                            label={day}
                          />
                        ))}
                      </FormGroup>
                      {touched.days && errors.days && (
                        <FormHelperText error>{errors.days}</FormHelperText>
                      )}
                    </Box>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <FormLabel component="legend">Select a until date</FormLabel>
                      <DateCalendar
                        value={values.untilDate}
                        onChange={(newValue) => setFieldValue('untilDate', newValue)}
                        // renderInput={(props: any) => (
                        //   <TextField
                        //     {...props}
                        //     fullWidth
                        //     error={Boolean(touched.untilDate && errors.untilDate)}
                        //     helperText={touched.untilDate && errors.untilDate}
                        //   />
                        // ) as any}
                      />
                      {touched.untilDate && errors.untilDate && (
                        <FormHelperText error>{errors.untilDate}</FormHelperText>
                      )}
                    </LocalizationProvider>

                    <LoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                      disabled={isSubmitting}
                    >
                      Submit
                    </LoadingButton>
                  </Stack>
                )}
              </Stack>
            </Scrollbar>
          </Paper>
        </Box>
      </Form>
    </FormikProvider>
  );
}
